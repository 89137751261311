import posthog from "posthog-js";
if (
  !window.location.host.includes("127.0.0.1") &&
  !window.location.host.includes("localhost") &&
  !window.location.host.includes("staging")
) {
  posthog.init("phc_U5wf8eE3C6ZnljKfBddtbge9MAbZnR5gJKXCBVeOyW0", {
    api_host: "https://eu.posthog.com",
    persistence: "memory",
    autocapture: false,
  });
}
